<template>
  <section id="">
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <DashboardCaseCardList
          :table-data="caselistArray"
          :loaded-data="Loaded"
          routename="casestatus"
        />
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
} from '@/libs/tientech/api'
import { Tientech_CaseInit } from '@/libs/tientech/case'
import DashboardCaseCardList from './Dashboard_CaseCardList.vue'

export default {
  components: {
    BRow,
    BCol,
    DashboardCaseCardList,
  },
  data() {
    return {
      pagename: 'dashboard',
      caselistArray: [],
      caselistObject: {},
      random_number: 0,
      Loaded: false,
      casestatus: {
        normal: 0,
        abnormal: 0,
        unknown: 0,
      },
      perPage: 10,
      page: 1,
    }
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.webConfigs[document.location.hostname]
      ? $themeConfig.webConfigs[document.location.hostname]
      : $themeConfig.app
    return {
      pagename: 'dashboard',
      appName,
    }
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getCasesList(q, perPage, offset) {
      const vuethis = this
      const queryParams = {
        casetype: 'barn',
        q: q || '',
        perPage: perPage || this.perPage,
        offset: offset || this.offset,
        sortBy: 'id',
        sortDesc: true,
        role: null,
        plan: null,
        status: null,
        searchkey: null,
        includedeviceinfo: false,
        callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
      }
      this.$socket.emit('casecloud_getcaselist', queryParams)
    },
    page_initialize() {
      try {
        // this.$moment.locale('zh-tw')
        setTimeout(this.getCasesList(), 100)
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply_casecloud_getcaselist',
            this.socketevent_reply_casecloud_getcaselist
          )
        } else {
          this.sockets.unsubscribe('reply_casecloud_getcaselist')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_casecloud_getcaselist(params) {
      try {
        const vuethis = this
        let errorloading = false
        if (
          params === undefined ||
          params.type === undefined ||
          params.type === 'error' ||
          params.msg === undefined
        ) {
          errorloading = true
        }
        const userData = Tientech_getCookie('userData')
        if (errorloading === true) {
          let errormsg = ''
          if (params.msg === 'user is undefined') {
            if (
              userData !== undefined &&
              userData !== null &&
              userData !== ''
            ) {
              if (window.tientech.socketio !== undefined) {
                axios.get('/updatetoken')
                window.tientech.socketio.disconnect()
                setTimeout(() => {
                  window.tientech.socketio.connect()
                  vuethis.getCasesList()
                }, 200)
              }
              // window.location.reload()
              return
            }
          }
          if (params.msg === 'database_disconnect') {
            errormsg = this.$t('error_common.database_disconnect')
          } else if (params.msg === 'timeout') {
            this.getCasesList()
            return
          } else {
            errormsg = this.$t('insufficient_permissions')
          }

          this.$swal({
            icon: 'error',
            title: errormsg,
            showConfirmButton: false,
            timer: 1000,
            buttonsStyling: false,
          }).then(result => {
            this.$cookies.remove('userData')
            this.$cookies.remove('userToken')
            this.$socket.disconnect()
            setTimeout(() => {
              this.$socket.connect()
            }, 200)
            axios.get('/updatetoken')
            this.$router.push({ name: 'Login' })
          })
          return
        }

        if (vuethis.Loaded === false) {
          vuethis.total_cases = 0
          vuethis.total_show_cases = 0
          vuethis.caselistObject = {}
          vuethis.caselistArray = []
          vuethis.Loaded = true
          vuethis.offset = 0
        }
        vuethis.total_cases = params.msg._total
        const tmpcases = params.msg.cases
        Object.keys(tmpcases).forEach(pn => {
          try {
            let obj = {
              sys: {
                pn,
              },
            }
            obj = Object.assign([], obj, tmpcases[pn])
            obj = Tientech_CaseInit(obj)

            if (obj.sys.status === 'normal') {
              vuethis.casestatus.normal += 1
            } else if (obj.sys.status === 'abnormal') {
              vuethis.casestatus.abnormal += 1
            } else if (obj.sys.status === 'unknown') {
              vuethis.casestatus.unknown += 1
            }
            if (vuethis.caselistObject[pn] === undefined) {
              vuethis.caselistArray.push(obj)
              vuethis.caselistObject[pn] = {
                index: vuethis.caselistArray.length - 1,
              }
              vuethis.offset += 1
            } else {
              vuethis.caselistArray[vuethis.caselistObject[pn].index] = obj
            }
          } catch (error) {
            console.log(error)
          }
        })

        let currentprocess = parseInt(
          (vuethis.caselistArray.length / vuethis.total_cases) * 100,
          10
        )
        if (currentprocess > 100) {
          currentprocess = 100
        }

        if (vuethis.offset < params.msg._total) {
          clearTimeout(vuethis.timer_socket_sender)
          vuethis.getCasesList(undefined, undefined, vuethis.offset)
          return
        }
        // finish
        const tmpelement = vuethis.caselistArray
        vuethis.caselistArray = []
        vuethis.caselistArray = tmpelement
        vuethis.caselistObject = { ...vuethis.caselistObject }

        vuethis.$forceUpdate()
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.nameOfTheClass .btn {
  padding: 10px;
}
</style>
