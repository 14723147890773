<template>
  <div>
    <template v-if="loadedData === false">
      <b-row
        class="match-height d-flex flex-column align-items-center justify-content-center"
        style="height: calc(50vh)"
      >
        <b-col
          class="d-flex flex-column align-items-center justify-content-center p-3"
        >
          <h3>{{ $t(`common.loading`) }}</h3>
          <div class="demo-inline-spacing">
            <b-spinner class="mr-1" variant="info" />
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-else-if="listData.length === 0 && loadedData === true">
      <b-row class="match-height">
        <b-col
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <h3>{{ $t('common.nodata') }}</h3>
          <b-img fluid :src="imgUrl" :alt="$t(`pages.${pagename}.pagetitle`)" />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="match-height d-sm-none">
        <b-col
          v-for="(item, key, index) in listData"
          :key="index"
          xs="12"
          md="6"
          lg="4"
          xl="3"
        >
          <b-card no-body class="d-flex flex-row">
            <b-img
              class="cardlist-img"
              :src="require('@/assets/images/slider/03.jpg')"
              alt="Card image cap"
              style=""
            />
            <b-card-body
              class="d-flex justify-content-start align-items-center flex-grow-1"
              style="padding: 1.5rem 0.5rem"
            >
              <b-card-title class="font-weight-bold">
                {{ item.sys.name }}
              </b-card-title>
            </b-card-body>
            <b-card-footer
              class="d-flex justify-content-start align-items-center"
              style="padding: 1.5rem 0.5rem"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'casestatus', params: { casepn: item.sys.pn } }"
                variant="success"
                size="sm"
              >
                <feather-icon icon="ChevronsRightIcon" />
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height d-none d-sm-flex justify-content-between">
        <b-col
          v-for="(item, key, index) in listData"
          :key="index"
          sm="6"
          md="4"
          lg="3"
          xl="auto"
          style="width: 300px"
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title class="font-weight-bold">
                {{ item.sys.name }}
              </b-card-title>
            </b-card-body>
            <b-img
              class="mb-1"
              fluid
              :src="require('@/assets/images/slider/03.jpg')"
              alt="Card image cap"
            />
            <b-card-footer class="text-center py-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: routename, params: { casepn: item.sys.pn } }"
                variant="success"
                class="d-flex justify-content-center align-items-center font-weight-bold"
              >
                <font-awesome-icon icon="gauge-high" class="mr-1" />
                <span class="align-middle">{{ $t('common.enter') }}</span>
              </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BSpinner,
    BRow,
    BCol,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loadedData: {
      type: Boolean,
      default: () => false,
    },
    routename: {
      type: String,
      default: () => 'casestatus',
    },
  },
  data() {
    return {
      showdevicetype: [],
      downImg: require('@/assets/images/illustration/notfounddata.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/illustration/notfounddata-w.png')
        return this.downImg
      }
      return this.downImg
    },
    typetableData() {
      const obj = {}
      if (this.tableData === undefined || this.tableData === null) {
        return {}
      }
      this.tableData.forEach(element => {
        const { type } = element.sys
        if (obj[type] === undefined) {
          obj[type] = []
          this.showdevicetype.push(type)
        }
        obj[type].push(element.sys)
      })
      Object.keys(obj).forEach(type => {
        obj[type].sort((a, b) => {
          if (a.location > b.location) {
            return -1
          }
          if (a.township > b.township) {
            return -1
          }
          if (a.name > b.name) {
            return -1
          }
          return 1
        })
      })
      return obj
    },
    listData() {
      try {
        if (this.tableData === undefined || this.tableData === null) {
          return null
        }
        const listArray = this.tableData
        listArray.sort((a, b) => {
          try {
            if (a.sys.name < b.sys.name) {
              return -1
            }
            if (a.sys.name > b.sys.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return listArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
  },
  mounted() {
    try {
      // this.$moment.locale('zh-tw')
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    resolveCaseTypeRoleIcon(role) {
      if (role === 'barn') return 'ArchiveIcon'
      if (role === 'greenhouse') return 'HomeIcon'
      if (role === 'aquaculture') return 'DropletIcon'
      if (role === 'solar') return 'ZapIcon'
      if (role === 'biogas') return 'ZapIcon'
      return 'AlertCircleIcon'
    },
    resolveCaseTypeColorRoleIcon(role) {
      if (role === 'barn') return 'primary'
      if (role === 'greenhouse') return 'success'
      if (role === 'aquaculture') return 'info'
      if (role === 'solar') return 'warning'
      if (role === 'biogas') return 'warning'
      return 'danger'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
